import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useField, ErrorMessage } from 'formik';

import { makeStyles, Theme } from '@material-ui/core/styles';

interface Props {
  name: string;
  label: string;
  [key: string]: any;
}

export const Input = ({ label, ...props }: Props) => {
  const [field, meta] = useField(props);

  const classes = useStyles();

  return (
    <TextField
      label={label}
      {...field}
      {...props}
    //   onBlur={(e) => {
    //     if (props.onBlur) {
    //       props.onBlur(e);
    //     }
    //     if (field.onBlur) {
    //       field.onBlur(e);
    //     }
    //   }}
      inputProps={{ readOnly: props.readOnly}}
	  InputLabelProps={{ shrink: true }}
      error={!!meta.touched && !!meta.error}
      helperText={<ErrorMessage name={field.name} />}
	  className={classes.root}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
	  marginBottom: theme.spacing(1)
	},
  }));
